.login-modal {
    overflow-y: auto;
}

.login-form-modal {
    .the-button {
        width: 100%;
        max-width: 270px;
    }
}

.login-modal-scroll {
    // background-color: red;
    // height: calc(100vh - 100px);

    img {
        max-width: 300px;
    }
}

.login-modal-fix-scroll {
    @media(max-width: 767px) {
        padding-bottom: 140px;
    }
}