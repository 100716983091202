@import "../../styles/variables.scss";

#perguntas-frequentes {
    #perguntas-frequentes-accordion {
        max-height: 400px;
        overflow-y: scroll;

        @media(max-width: 768px) {
            max-height: calc(100vh - 250px);
        }

        .card {
            border-radius: 0;
            border-top: 1px solid $cl-grey;
            border-bottom: 0;
            border-left: 0;
            border-right: 0;
        }

        .card-header {
            border-top: 0;
            background-color: $cl-white !important;
            padding-top: 24px;
            padding-bottom: 24px;
            border-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .card-body {
            padding-left: 0;
            padding-right: 0;
        }

        .perguntas-frequentes-chevron {
            width: 7px;
            height: 12px;
            
            &.opened {
                width: 12px;
                height: 7px;
            }

            &.closed {
                width: 7px;
                height: 12px;
            }
        }
    }

    @media (max-width: 991px) {
        .fale-conosco-col-desktop {
            display: none !important;
        }

        .fale-conosco-col-mobile {
            display: block !important;
        }
    }

    @media (min-width: 991px) {
        .fale-conosco-col-mobile {
            display: none !important;
        }
    }
}