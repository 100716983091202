@import "../../styles/variables.scss";

.home-menu {
    padding: 20px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    background-color: #FFF;
    box-shadow: 0px 5px 16px #f3f3f3;
}

.home-menu-container {
    display: flex;
    max-width: 1220px;
    margin: 0 auto;
}

.home-menu-column {
    display: flex;
    align-items: center;
}

.home-menu-column-right {
    margin-left: auto;
}

.home-menu-logo {
    max-width: 90px;
}

.home-menu-hamburguer,
.home-menu-close {
    background-color: transparent;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    border: none;
}

.home-menu-hamburguer {
    background-image: url('../../assets/images/menu-hamburguer.png');
}

.home-menu-close {
    background-image: url('../../assets/images/menu-close.png');
    background-size: auto;
}

.home-menu-overlay,
.home-menu-desktop-overlay {
    background-color: rgba(0, 0, 0, .4);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 999;
}

.home-menu-overlay-opened {
    display: block;

    @media(min-width: 992px) {
        display: none;
    }
}

.home-menu-desktop-overlay {
    display: none;
    top: 92px;
}

.home-menu-desktop-opened {
    @media(min-width: 992px) {
        display: block;
    }
}

.home-menu-aside {
    background-color: #FFF;
    box-shadow: -8px 0px 30px -12px rgba(0, 0, 0, 0.1);
    border-radius: 16px 0px 0px 0;
    position: fixed;
    right: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    
    @media(min-width: 320px) {
        width: 80%;
    }
}

.home-menu-aside-container {
    display: flex;
}

.home-menu-aside-column-right {
    margin-left: auto;
}

input[type=text].home-menu-search-input,
input[type=text].home-menu-desktop-search-input {
    background-image: url('../../assets/images/search-input.png');
    background-repeat: no-repeat;
    background-size: 12px;
    border: none; 
    background-position: 0 50%;
    padding-left: 20px;
}

input[type=text].home-menu-desktop-search-input {
    background-color: #F3F1F1;
    font-size: 14px;
    background-position-x: 10px;
    padding-left: 30px;
    width: 220px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-right: 10px;
}

.home-menu-search-input,
.home-menu-desktop-search-input {
    &:focus {
        outline: none;
    }
}

.home-menu-aside-column {
    display: flex;
    align-items: center;
}

.home-menu-header {
    padding: 25px 20px;
}

.home-menu-content {
    padding: 0;
}

.home-menu-footer {
    box-shadow: 0px 1px 35px rgba(46, 32, 32, 0.05);
    margin-top: auto;
    padding: 30px;
    text-align: center;

    a:not(.home-menu-btn-facaparte) {
        color: $cl-pink;
        text-decoration: none;
        padding-bottom: 2px;
        border-bottom: 1px solid $cl-pink;

        &:hover {
            color: $cl-pink;
        }
    }
}

.home-menu-btn-facaparte {
    
}

.home-menu-btn-facaparte {
    font-size: 16px;
    color: $cl-pink;
    background-color: transparent;
    font-weight: bold;
    border-radius: 40px;
    border: 2px solid $cl-pink;
    display: block;
    padding: 10px;
    width: 100%;
    max-width: 200px;
    margin: 0 auto 15px;
}

.home-menu-items {
    padding: 0;

    li {
        list-style: none;
        padding: 15px 25px;

        a {
            color: $cl-black;
            display: block;
        }
    }
}

.home-menu-item-has-subitems {

}

.home-menu-item-has-subitems {
    a {
        background-image: url('../../assets/images/arrow-pink-right.png');
        background-repeat: no-repeat;
        background-position: center right;
    }
}

.home-menu-item-opened {
    background-color: #F3F1F1;
    border-bottom: 3px solid $cl-pink;

    a {
        background-image: url('../../assets/images/arrow-pink-up.png');
        font-weight: bold;
    }
}

.home-menu-subitems {
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 270px;
    overflow-y: auto;

    li {

        a {
            font-weight: normal;
            color: #999 !important;
        }
    }
}

a.home-menu-has-subcategories {
    background-image: url('../../assets/images/arrow-grey-right.png');
    background-repeat: no-repeat;
    background-position: center right;
    font-weight: normal;
    color: #999 !important;
}

.home-menu-back-navigation {
    color: $cl-pink;
    background-image: url('../../assets/images/left-chevron.png');
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 10px;
    padding-left: 20px;
    line-height: 47px;

    &:hover {
        color: $cl-pink;
    }

    span {
        border-bottom: 1px solid $cl-pink;
        padding-bottom: 2px;
        font-size: 13px;
    }
}

.home-menu-desktop-column-right {
    display: flex;
    width: 100%;
}

.home-menu-desktop-left {

}

.home-menu-desktop-right {
    margin-left: auto;
}

.home-menu-desktop-button,
.home-menu-desktop-button-outline {
    font-size: 14px;
    color: $cl-pink;
    border: 3px solid $cl-pink;
    border-radius: 40px;
    padding: 10px 20px;
    font-weight: bold;
    margin-left: 20px;

    &:hover {
        color: $cl-pink;
    }
}

.home-menu-desktop-button-outline {
    border: none;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
    font-weight: 500;
}

ul.home-menu-desktop-link-list {
    padding-left: 0;
    display: flex;
    margin: 0 0 0 40px;

    position: absolute;
    top: 0;
    height: 86px;
    padding-top: 6px;

    li {
        align-items: center;
        list-style: none;
        display: inline-block;
        padding: 0 20px;

        display: flex;

        a {
            color: #221E1F;
            font-weight: 500;

            align-items: center;
        }
    }
}

.home-menu-desktop-link-arrow {
    padding-right: 20px;
    background-image: url('../../assets/images/arrow-pink-down.png');
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center right;
}

.home-menu-desktop-link-active {
    background-color: #F3F1F1;
    box-shadow: 0px 6px 0px $cl-pink;
}

.home-menu-desktop-form {
    display: unset;
}

.home-menu-desktop {
    min-height: 500px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFF;
    box-shadow: 1px 1px 1px #CCC;
    border-top: 1px solid #DDD;
}

.home-menu-desktop-container {
    margin: 0 auto;
    max-width: 1235px;
    padding: 40px 15px;
    // overflow-y: auto;
    // overflow-x: hidden;
    // height: 440px;

    .home-menu-back-navigation {
        line-height: normal;
    }
}

.home-menu-desktop-vertudo {
    color: $cl-pink;
    font-size: 14px;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid $cl-pink;

    &:hover {
        color: $cl-pink;
    }
}

.home-menu-desktop-category-name {
    font-size: 16px;
    font-weight: bold;
}

.home-menu-desktop-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: 380px;
}

.home-menu-desktop-categories {
    ul {
        padding-left: 0;
        display: grid;
        grid-template-columns: auto auto;

        li {
            list-style: none;

            a {
                color: #999999;
                font-size: 14px;
                text-decoration: none;
            }

            padding: 7px 0;
        }
    }
}

.home-menu-desktop-todas {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    h6 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
        line-height: normal;
        padding-top: 15px;
        padding-left: 7px;
    }

    ul {
        padding-left: 0;   
    }

    li {
        list-style: none;
        padding: 7px;
    }

    a {
        color: #999999;
        font-size: 14px;
        text-decoration: none;

        &:hover {
            color: #999999;
        }
    }
}

.home-menu-desktop-exibicao {
    display: flex;
}

.home-menu-desktop-exibicao-revista {
    width: 156px;
    height: 205px;
}

.exibicao-revista-1 {}

.exibicao-revista-2 { 
    position: relative;
    bottom: -40px;
    margin: 0 10px;
}
                                                
.exibicao-revista-3 {}

.banner-home-logado-container {
    @media(max-width: 768px) {
        &.container {
            max-width: 600px;
        }
    }

    @media(max-width: 600px) {
        &.container {
            max-width: 500px;
        }
    }

    @media(max-width: 500px) {
        &.container {
            max-width: 400px;
        }
    }

    @media(max-width: 400px) {
        &.container {
            max-width: 300px;
        }

        button.the-button {
            font-size: 20px;
        }
    }
        
    .slick-slider .slick-track, .slick-slider .slick-list {
        // max-width: 100%;
    }
}