@import "../../styles/variables.scss";

.home-materias {
    padding: 40px 0;
    background-image: linear-gradient(0deg, #f9f8f8 50%, #FFF 50%);
    min-height: 100%;
//     padding-top: 40px + $home-nav-height;
padding-top: 100px;

@media(max-width: 767px) {
    padding-top: 75px;
}
}

.home-materias-titulo {
    font-size: 24px;
    font-family: 'Montserrat';
    text-align: center;
    margin: 0 auto;
    max-width: 205px;
    margin-bottom: 30px;

    @media(min-width: 769px) {
        max-width: 100%;
        font-size: 32px;
        margin-bottom: 40px;
    }
}

.home-materias-slider {
    padding: 0;
}

.home-materias-slide-wrap {
    padding: 0 10px;
}

.home-materias-slide-card {
    background: #FFFFFF;
    box-shadow: 0px 26px 31px -20px rgba(46, 32, 32, 0.15);
    border-radius: 0px 0px 16px 16px;
    margin-bottom: 25px;

    img {
        width: 100%;
    }

    small {
        font-size: 14px;
        color: #999;
        margin-bottom: 5px;
        display: block;
    }

    h4 {
        font-family: 'Montserrat';
        font-size: 20px;
    }

    p {
        font-size: 14px;
        color: #999;
        line-height: 22px;
    }

    a {
        text-decoration: none;
        color: $cl-pink;
        padding-bottom: 1px;
        border-bottom: 1px solid $cl-pink;

        &:hover {
            color: $cl-pink;
        }
    }
}

.home-materias-slide-content {
    padding: 24px;
}