@import "../../styles/variables.scss";

#home-faca-parte {
    padding-top: 100px;

    @media(max-width: 767px) {
        padding-top: 75px;        
    }
}

.home-faca-parte {
    padding: 100px 0 100px 0;
    background-image: url("../../assets/bg/bg-2.png");
    background-size: 100% 65%;
    background-repeat: no-repeat;
    background-position: center bottom;
//     background-color: $cl-light-grey;
background-color: #f3f1f1;
//     height: 100%;

    @media (max-width: 767px) {
        padding-top: 0;
        padding-bottom: 75px
    }

    .home-faca-parte-box {
        background-color: $cl-white;
        width: 70%;
        height: auto;
        padding: 56px;
        border-radius: 16px;
        box-shadow: 0px 26px 31px -20px rgba(46, 32, 32, 0.15);

        @media (max-width: 991px) {
            width: 90% !important;
            padding: 20px;
        }

        @media (min-width: 992px) {
            .home-faca-parte-box-text-div {
                padding-right: 9%;
            }
        }

        .home-faca-parte-thumbnail {
            width: 100%;
            border-radius: 16px;
        }

        .home-faca-parte-video-play {
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 10%;
            left: 0;
            background-image: url("../../assets/images/play-icon.png");
            background-repeat: no-repeat;
            background-position: center center;
        }

        .home-faca-parte-video-text {
            border-bottom: 1px solid $cl-pink;
            width: fit-content;

            @media(max-width: 991px) {
                font-size: 16px
            }
        }

        @media (min-width: 992px) {
            .home-faca-parte-box-text-div-logged {
                padding-right: 5%;
            }
        }

        .home-faca-parte-complete-historic-logged {
            border-bottom: 1px solid $cl-pink;
            width: fit-content;
        }

        .home-faca-parte-logged-slide-item {
            padding: 0 15px;
        }

        .slick-arrow {
            &.slick-next {
                width: 11px;
                height: 20px;
            }
            &.slick-prev {
                width: 11px;
                height: 20px;
            }
        }
    }
}

.home-faca-parte-box-btn-faca-parte {
    @media(max-width: 769) {
        font-size: 20px !important;
    }

    @media(min-width: 1500px) {
        font-size: 24px !important;
    }
}