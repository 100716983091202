.esqueci-senha-modal {
    // height: 100%;
    overflow-y: auto;
}

.esqueci-senha-form-modal {
    .the-button {
        width: 100%;
        max-width: 270px;
    }
}