@import "./variables.scss";

.justify-flex-end { justify-content: flex-end; }

.display-table { display: table }
.display-table-cell { display: table-cell }

.b-0 { bottom: 0 }

.l-45-p { left: 45% }

.l-0 { left: 0 }

.r-0 { right: 0 }

.z-index-99 { z-index: 9; }

.vertical-align-middle { vertical-align: middle }

.overflow-hidden { overflow: hidden }

.f-montserrat { font-family: 'Montserrat', sans-serif }
.f-lato { font-family: 'Lato', sans-serif }

.f-14 { font-size: 14px }
.f-16 { font-size: 16px }
.f-18 { font-size: 18px }
.f-20 { font-size: 20px }
.f-24 { font-size: 24px }
.f-32 { font-size: 32px }
.f-40 { font-size: 40px }
.f-60 { font-size: 60px }

.f-w-400 { font-weight: 400 }
.f-w-500 { font-weight: 500 }
.f-w-600 { font-weight: 600 }
.f-w-700 { font-weight: 700 }

.f-l-h-22-4 { line-height: 22.4px }
.f-l-h-25-6 { line-height: 25.6px }
.f-l-h-28 { line-height: 28px }
.f-l-h-28-8 { line-height: 28.8px }
.f-l-h-33-6 { line-height: 36.6px }
.f-l-h-45 { line-height: 45px }

.text-white { color: #FFF }
.text-orange-white { color: $cl-orange-white }
.text-pink { color: $cl-pink !important };
.text-grey { color: $cl-greyFaq }
.text-silver { color: $cl-silver; }
.text-black { color: $cl-black }
.text-orange { color: #F2994A; }

.bg-pink { color: $cl-pink }
.bg-white { color: $cl-white }

.m-t-10 { margin-top: 10px }
.m-t-15 { margin-top: 15px }
.m-t-20 { margin-top: 20px }
.m-t-25 { margin-top: 25px }
.m-t-30 { margin-top: 30px }
.m-t-40 { margin-top: 40px }
.m-t-50 { margin-top: 50px }
.m-t-60 { margin-top: 60px }
.m-t-70 { margin-top: 70px }
.m-t-75 { margin-top: 75px }
.m-t-100 { margin-top: 100px; }
.m-t-90 { margin-top: 90px }
.m-t-94 { margin-top: 94px }

@media (max-width: 991px) {
    .m-b-md-72 { margin-bottom: 72px }
    .m-b-md-70 { margin-bottom: 70px }
    .m-b-md-28 { margin-bottom: 28px !important }
    .m-b-md-25 { margin-bottom: 25px }
    .m-b-md-24 { margin-bottom: 24px }
    .m-b-md-16 { margin-bottom: 16px !important }

    .text-center-md { text-align: center }

    .m-0-auto-md { margin: 0 auto }
}

@media (max-width: 767px) {
    .m-t-xs-30 {
        margin-top: 30px;
    }

    .w-xs-100 {
        width: 100%;
    }


    .pd-xs-0 {
        padding: 0 !important;   
    }
}

.p-t-5 { padding-top: 5px }
.p-t-50 { padding-top: 50px }
.p-t-100 { padding-top: 100px }

.p-b-0 { padding-bottom: 0; }

.m-b-0 { margin-bottom: 0px; }
.m-b-4 { margin-bottom: 4px; }
.m-b-10 { margin-bottom: 10px }
.m-b-12 { margin-bottom: 12px }
.m-b-15 { margin-bottom: 15px }
.m-b-16 { margin-bottom: 16px }
.m-b-18 { margin-bottom: 18px }
.m-b-20 { margin-bottom: 20px }
.m-b-24 { margin-bottom: 24px }
.m-b-25 { margin-bottom: 25px }
.m-b-30 { margin-bottom: 30px }
.m-b-32 { margin-bottom: 32px }
.m-b-36 { margin-bottom: 36px }
.m-b-40 { margin-bottom: 40px }
.m-b-50 { margin-bottom: 50px }
.m-b-64 { margin-bottom: 64px }
.m-b-68 { margin-bottom: 68px }

.p-l-0 { padding-left: 0 !important }
.p-l-5 { padding-left: 5px; }
.p-l-16 { padding-left: 16px }
.p-l-47 { padding-left: 47px }
.p-l-110 { padding-left: 110px }
.p-l-120 { padding-left: 120px }
.p-l-130 { padding-left: 130px }

.p-y-15 { padding-left: 15px; padding-right: 15px; }

.p-r-47 { padding-right: 47px }
.p-r-130 { padding-right: 130px }

.p-24 { padding: 24px; }
.p-35 { padding: 35px; }

.m-b-16 { margin-bottom: 16px }
.m-b-25 { margin-bottom: 25px }
.m-b-30 { margin-bottom: 30px }
.m-b-40 { margin-bottom: 40px }
.m-b-46 { margin-bottom: 46px }
.m-b-56 { margin-bottom: 56px }
.m-b-75 { margin-bottom: 75px }
.m-b-76 { margin-bottom: 76px }

.m-l-auto { margin-left: auto }
.m-l-40 { margin-left: 40px }
.m-l-20 { margin-left: 20px }
.m-l-3 { margin-left: 3px }

.w-520 { max-width: 520px }
.w-450 { max-width: 450px }
.w-300 { max-width: 300px }
.w-120 { max-width: 120px }
.w-90 { max-width: 90px }
.w-82 { max-width: 82px }
.w-66 { max-width: 66px }
.w-60 { max-width: 60px }

.w-70-p { width: 70% }
.w-90-p { width: 90% }
.w-95-p { width: 95% }
.w-100-p { width: 100% }

.w-50-p-i { width: 50% !important }
.w-70-p-i { width: 70% !important }

.h-500 { height: 500px; }

.cursor-pointer { cursor: pointer }

.m-x-auto { margin-left: auto; margin-right: auto; }

.position-relative { position: relative; }

.text-decor-line { text-decoration-line: underline }

.the-button-outline {
    border-width: 2px;
    border-style: solid;
    border-color: #FFF;
    border-radius: 40px;
    padding: 5px 20px;
}

.slide-marcas-mobile {
    // width: 70%;
    // border: 1px solid #000;
    // min-width: 200px;
}

.slide-marcas-mobile-image {
    // width: 70%;
}

.justify-content-center {
    justify-content: center;
}

.m-t-xs-0 {
    @media(max-width: 767px) {
        margin-top: 0 !important;
    }
}

.the-footer-termos-condicoes {
    max-height: 70vh;
    overflow-y: scroll;
}

.the-footer-politica-privacidade{
    max-height: 70vh;
    overflow-y: scroll;
}