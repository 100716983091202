@import "../../styles/variables.scss";

.home-titulos-destaque {
    padding: 50px 0 110px;
    background-color: #F3F1F1;
    height: 100%;

    @media(max-width: 992px) {
        background-image: url("../../assets/bg/bg-2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 250px;
        padding-top: 50px;
    }

    @media(max-width: 320px) {
        background-position: center 310px;
    }

    @media(max-width: 375px) {
        background-position: center 320px;
    }

    @media(max-width: 425px) {
        background-position: center 330px;
    }

    @media(max-width: 768px) {
        background-position: center 280px;
        padding-bottom: 0px;
    }
}

.home-titulos-destaque-titulo {
    font-family: 'Montserrat';
    font-size: 32px;
    margin: 0 auto;
    text-align: center;
    max-width: 470px;
    line-height: 44px;

    @media(max-width: 992px) {
        font-size: 16px;
        max-width: 215px;
        line-height: 22px;
    }
}

.home-titulos-destaque-card {
    background: #FFFFFF;
    box-shadow: 0px -14.75px 17.5919px -11.3496px rgba(46, 32, 32, 0.15);
    border-radius: 10px;
}

.home-titulos-destaque-content {

}

.home-titulos-section-container {
    max-width: 1220px;
    margin: 0 auto;
    display: flex;
}

.home-titulos-destaque-slides {
    margin: 25px 0 50px;
}

.home-titulos-destaque-slide {
    padding: 15px 0;
}

.home-titulos-section {
    padding: 100px 0;
    background-image: url("../../assets/bg/bg-2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 430px;
    margin-top: 120px;

    @media(max-height: 1000px) {
        margin-top: 75px;
    }

    @media(max-width: 992px) {
        margin: 0 15px;
        background-image: none;
        padding-top: 25px;
    }

    @media(max-width: 767px) {
        height: auto;
        padding-bottom: 0;
    }
}

.home-titulos-section-home {
    padding-left: 15px;
    
    h3 {
        color: #FFF;
        font-size: 40px;
        font-family: 'Montserrat';
        max-width: 380px;
        margin-top: 70px;
    }

    @media(max-width: 992px) {
        display: none;
    }
}

.home-titulos-destaque-card-container {
    background-color: #FFF;
    margin-left: auto;
    max-width: 830px;
    width: 100%;
    position: relative;
    // height: 510px;
    margin-top: -140px;
    border-radius: 16px;
    box-shadow: 0px 26px 31px -20px rgba(46, 32, 32, 0.15);
    padding: 50px 35px;

    @media(max-width: 1024px) {
        max-width: 700px;
    }

    @media(max-width: 992px) {
        max-width: 100%;
        width: 100%;
        margin-top: 0;
//         margin-bottom: 50px;
margin-bottom: 75px;
    }
}

.home-titulos-destaque-revistas-slide {

}

.home-titulos-destaque-revistas-slide-image-container {
    outline: none;
}

.home-titulos-destaque-revistas-slide-image {
    width: 67px;
    height: 88px;
}

.home-titulos-detail-revista {
    display: flex;
    margin-bottom: 40px;

    @media(max-width: 992px) {
        margin-top: 25px;
        display: block;
        text-align: center;
    }

    img {
        width: 260px;
        height: 346px;
        border-radius: 16px;

        @media(max-width: 992px) {
            width: 100%;
            height: unset;
            max-width: 100%;
        }
    }
}

.home-titulos-detail-revista-info {
    padding: 20px 40px 0;

    @media(max-width: 992px) {
        padding-left: 0;
        padding-right: 0;
    }

    h3 {
        font-family: 'Montserrat';
        font-size: 24px;
    }

    small {
        color: #999;
        font-size: 14px;
        display: block;
        padding: 12px 0;
    }

    p {
        font-size: 16px;
        color: #6B6B6B;
        margin: 0;
    }

    a {
        color: $cl-pink;
        padding-bottom: 1px;
        border-bottom: 1px solid $cl-pink;;

        &:hover {
            color: $cl-pink;
        }
    }

    a.link {
        background-color: $cl-pink;
        color: #FFF;
        font-size: 18px;
        border-radius: 40px;
        padding: 12px 30px;
        font-weight: bold;
        margin: 15px 0;
        display: inline-block;

        &:hover {
            color: #FFF;
        }
    }
}

.home-titulos-section-home-mobile {
    margin: 0 auto;
    max-width: 160px;

    h3 {
        font-size: 24px;
        text-align: center;
        color: #FFF;

        
    }
}

.home-titulos-section-home,
.home-titulos-section-home-mobile {
    span {
        // opacity: .5;
        color: #fffadf;
        display: block;
    }
}