@import "../../styles/variables.scss";

.home-footer {
    padding: 100px 0 20px 0;

    .home-footer-logo-desktop {
        width: 205px;
        height: 92px;
    }

    .home-footer-text {
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        margin-bottom: 15px;
    }

    .home-footer-stores-logo {
        width: 161px;
    }
}