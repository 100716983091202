@import "../../styles/variables.scss";

article.the-modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
}

.the-modal-wrap {
    display: table;
    width: 100%;
    height: 100%;
}

.the-modal-container {
    display: table-cell;
    vertical-align: middle;

    @media(max-width: 991px) {
        vertical-align: top;
    }
}

.the-modal-content {
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 0px 26px 31px -20px rgba(46, 32, 32, 0.15);
    background-color: $cl-white;
    padding: 40px;
    border-radius: 16px;
    position: relative;

    @media(max-width: 991px) {
        border-radius: 0;
        max-width: 100%;
        // height: 100%;
        height: 100vh;
    }

    &.md {
        max-width: 620px;
    }
}

button.the-modal-close {
    background-image: url("../../assets/images/close.png");
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 99;
    position: absolute;
    right: 20px;
    width: 20px;
    height: 20px;
    top: 20px;
    border: none;
    color: transparent;
}