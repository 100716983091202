@import "../../styles/variables.scss";

.home-lancamentos {
    padding: 100px 0 0 0;
//     background: linear-gradient(to top, $cl-light-grey 50%, $cl-white 50%);
background: linear-gradient(to top, #f3f1f1 50%, #f3f1f1 50%);

    @media(max-width: 767px) {
        background: #FFF;
        padding-top: 75px;
    }

    .home-lancamentos-slide-item-magazine {
        padding: 0 15px;
    
        img {
            max-width: 100%;
            border-radius: 15px;
        }
    }
    
    .home-lancamentos-lancamento-slide-info {
        box-shadow: 0px 5px 16px rgba(185, 185, 185, 0.25);
        border-radius: 8px;
        width: 250px;
        padding: 25px;
        margin: -40px auto 20px;
    
        @media(max-width: 767px) {
            width: calc(100% - 40px)
        }
    }
    
    .slick-arrow {
        &.slick-next {
            width: 11px;
            height: 20px;
        }
        &.slick-prev {
            width: 11px;
            height: 20px;
        }
    }
}
