@import "../../styles/variables.scss";

.banner-home {
    width: 100%;
    height: 100%;
}

.banner-home-wrap {
    display: table;
    width: 100%;
    height: 100%;
    padding-top: 82px;
}
                
.banner-home-content {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;

    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    // background-size: contain;

    @media(min-width: 768px) {
        background-image: url('../../assets/images/home/test-1920x1080.png');
        // background-size: 400px;
    }

    // @media(min-width: 992px) {
    //     background-size: 600px;
    // }

    // @media(min-width: 1200px) {
    //     background-size: 700px;
    // }

    // // height

    // @media(max-height: 900px) {
    //     background-size: 600px;
    // }

    // @media(max-height: 800px) {
    //     background-size: 500px;
    // }
}

.banner-home-container {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1220px;

    @media(max-width: 767px) {
        padding-top: 40px;
    }
}

.banner-home-title {
    font-size: 25px;
    font-family: 'Montserrat';
    color: #6B6B6B;
    margin: 0 auto 15px;
    max-width: 300px;

    @media(min-width: 768px) {
        margin: 0 0 45px;
        font-size: 48px;
        max-width: 500px;
    }
}

.banner-home-subtitle {
    color: #999;
    line-height: 25px;
    font-size: 15px;
    max-width: 300px;
    margin: 0 auto;

    @media(min-width: 768px) {
        margin: 0 0 125px;
        max-width: 445px;
        font-size: 24px;
        line-height: 38px;
    }
}

.banner-home-image-mobile {
    margin-bottom: 30px;

    @media(min-width: 768px) {
        display: none;
    }
    
    img {
        margin: 25px auto;
        display: block;
        width: 100%;
        max-width: 320px;
    }

}

.banner-home-cta {
    font-size: 15px;
    color: #FFF;
    background-color: $cl-pink;
    padding: 20px 50px;
    border-radius: 40px;
    max-width: 300px;
    margin: 0 auto 25px;
    display: block;
    font-weight: bold;
    text-align: center;

    &:hover {
        color: #FFF;
    }

    @media(min-width: 321px) {
        font-size: 20px;
        margin: 0 auto 25px;
        display: block;
    }

    @media(min-width: 768px) {
        margin: 0 0 25px;
    }
}

.banner-home-saiba-mais {
    color: #6B6B6B;
    text-align: center;
    margin-bottom: 25px;

    @media(min-width: 768px) {
        margin: 0 0 25px;
        width: 300px;
    }

    a {
        color: $cl-pink;
        text-decoration: none;
        border-bottom: 1px solid $cl-pink;
        padding-bottom: 2px;

        &:hover {
            color: $cl-pink;
        }
    }
}

.banner-home-arrow-link {
    width: 12px;
    height: 7px;
    display: block;
    margin: 0 auto 25px;
    background-image: url('../../assets/images/arrow-pink-down.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.banner-home-arrow-link-desktop {
    display: none;

    @media(min-width: 768px) {
        display: block;
        position: absolute;
        bottom: 50px;
        width: 100%;
    }

    .banner-home-arrow-link {
        width: 40px;
        height: 40px;
        text-align: center;
        margin: 0 auto;
        background-color: #FFF;
        border-radius: 50%;
        border: 1px solid #FFF;
        background-size: 50%;
        background-position: center center;
    }
}

.banner-home-logado-content {
    background-image: url("../../assets/bg/bg-2.png");
    background-size: cover;
    width: 100%;
    height: 100%;
    align-items: center;

    @media (max-width: 892px) {
        padding-top: 82px;
    }

    .banner-home-logado-title {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 48px;
        color: $cl-white;
        margin-bottom: 24px;
    }

    .banner-home-logado-text {
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 24px;
        color: $cl-white;
        margin-bottom: 64px;
    }

    .banner-home-logado-button {
        font-family: 'Lato', sans-serif;
        font-weight: 700;
    }

    .banner-home-logado-banner-selector {
        display: flex !important;
        justify-content: start;
        margin-bottom: 3%;

        li {
            width: 24px;
            height: 8px;
            opacity: 0.5;
            background-color: $cl-white;
            border-radius: 16px;
            margin-right: 16px;

            button {
                opacity: 0;
            }

            &.slick-active {
                width: 40px;
                opacity: 1;
            }
        }
    }
}

.banner-home-logado-content-mobile {
    background-image: url("../../assets/bg/bg-2.png");
    background-size: cover;
    vertical-align: middle;
    display: table-cell;
    padding-bottom: 10%;

    @media (max-width: 892px) {
        padding-top: 82px;
    }

    @media (max-width: 767px) {
        padding-top: 40px;
    }

    .banner-home-logado-title {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 25px;
        color: $cl-white;
        margin-bottom: 24px;
    }

    .banner-home-logado-text {
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: $cl-white;
        // margin-bottom: 64px;
        margin-bottom: 32px;
    }

    .banner-home-logado-button {
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        width: 80%;

        @media(max-width: 400px) {
            font-size: 20px !important;
        }
    }

    .banner-home-logado-banner-selector {
        display: flex !important;
        justify-content: center;

        li {
            width: 24px;
            height: 8px;
            opacity: 0.5;
            background-color: $cl-white;
            border-radius: 16px;
            margin-right: 16px;

            button {
                opacity: 0;
            }

            &.slick-active {
                width: 40px;
                opacity: 1;
                background-color: $cl-pink;
            }
        }
    }
}